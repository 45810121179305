/* 
    ===================================================================================================================
    Name: input
    Type: material custom component
    Scope: all
    Description: Material input to enter info
    ===================================================================================================================
*/

/* 
    ===================================================================================================================
    Config
    Local vars, placeholders, mixins and functions
    Note: Reference global vars by assigning to local variable to prevent overriding their values, use global
    placeholders, mixins and functions before writing your own
    ===================================================================================================================
*/

// $some-local-var: $some-global-var

/* 
    ===================================================================================================================
    Core
    The actual rule declaration with nested elements and modifiers
    ===================================================================================================================
*/

.mat-form-field-appearance-legacy {
    .mat-form-field-label {
        color: $gray-700 !important;
    }
    .mat-form-field-wrapper {
        padding: 0.5 !important;
    }
}

.mat-form-field-infix {
    padding: 0;
    border: 0;
    margin-right: 0.5rem;
    input {
        line-height: 1.5rem;
        position: relative;
        top: 0.5rem;
        padding: 0 1rem;
        padding: 4px 14px;
        border-radius: 3px;
    }

    .mat-select-trigger {
        background-color: $gray-200;
        border-radius: 3px;
        padding: 0.5rem;
    }
    .mat-select-value {
        background: transparent;
    }
    textarea {
        background-color: $gray-200;
        border-radius: 3px;
        padding: 10px 15px !important;
        min-height: 65px;
    }
    label {
        font-size: 1.25rem;
    }
}

.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element,
.mat-form-field.mat-focused .mat-form-field-label {
    color: #8b9ba3 !important;
    caret-color: #8b9ba3 !important;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
    color: #65737a;
}

.engagement-csa-config .mat-select-placeholder {
    background: transparent;
}

.mat-form-field-required-marker {
    color: #d41a1a !important;
}
