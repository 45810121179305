/*
    ===================================================================================================================
    Name: fx-data-table
    Type: component
    Scope: JST pages
    Description:
    Reusable custom table component using divs
    ===================================================================================================================
*/

/*
    ===================================================================================================================
    Config
    Local vars, placeholders, mixins and functions
    Note: Reference global vars, placeholders, mixins and functions before writing your own
    ===================================================================================================================
*/

$header-height: 80px;

@function col-width($column-width, $total-columns: $default-cols) {
    @return (100% / $total-columns) * $column-width;
}

/*
    ===================================================================================================================
    Base
    Common styles that apply grouped selectors i.e. .fx-block, .fx-block-descriptor {}
    Note: You may use [class*=""], [class^=""] etc.. but be careful
    ===================================================================================================================
*/

.fx-data-table {
    $this: &;
    @include fx-table();
    color: $gray-900;

    &-head,
    &-summary,
    &-body {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
    }

    &-row {
        padding: $gutter-size/2 0;
        color: $gray-700;
        &--head {
            position: sticky;
            top: $header-height;
            z-index: $z-index-0;
            border-top: none;
            background-color: #f2f6f6;
            #{$this}-head-title-link {
                color: $brand-primary;
                mat-icon {
                    display: block;
                    position: relative;
                    top: -2px;
                    left: 2px;
                    width: 14px;
                    height: 14px;
                    font-size: 14px;
                }
            }
        }
        &--summary {
            min-height: px-to-rem(40px);
            line-height: px-to-rem(30px);
            border-bottom: 2px solid $gray-300;
            font-weight: 600;
            background-color: $gray-200;
            color: $gray-600;
        }
        &--body {
            border-bottom: 1px solid #e8e8e8;
            padding: 30px 0;
            border-bottom: 1px solid $gray-300;
            min-height: px-to-rem(40px);
            line-height: px-to-rem(30px);
            line-height: 1.3rem;
            align-items: center;
            transition: ease-in-out 0.3s;
            background-color: white;
        }
    }

    &--client {
        font-size: 16px;
        #{$this}-row {
            @include grid(22);
        }
        #{$this}-col {
            &--subregion {
                @include col(2, 22);
            }
            &--status,
            &--matter-partner,
            &--matter-manager,
            &--scoped {
                @include col(3, 22);
            }
            &--family-group {
                @include col(5, 22);
                font-size: 15px;
                font-weight: 500;
                color: $gray-900;
            }
            &--prior-year,
            &--selected-year {
                @include col(3, 22);
                text-align: right;
            }
            &--details {
                @include col(2, 22);
                text-align: center;
            }
            &--scoped {
                text-align: right;
                .fx-data-table-head-title {
                    text-align: right;
                }
            }
            &--relationship {
                @include col(2, 22);
            }
            &--scheduled-date {
                @include col(2, 22);
            }
        }
    }

    &--client-expand {
        font-size: 16px;
        #{$this}-row {
            @include grid(15);
        }
        #{$this}-col {
            &--subregion {
                @include col(2, 15);
            }
            &--matter-partner,
            &--matter-manager {
                @include col(3, 15);
            }
            &--family-group {
                @include col(5, 15);
                font-size: 15px;
                font-weight: 500;
                color: $gray-900;
            }
            &--scoped {
                text-align: right;
                .fx-data-table-head-title {
                    text-align: right;
                }
            }
        }
    }

    &--client-expand-old {
        font-size: 16px;
        #{$this}-row {
            @include grid(13);
        }
        #{$this}-col {
            &--subregion {
                @include col(2, 13);
            }
            &--matter-partner,
            &--matter-manager {
                @include col(3, 13);
            }
            &--family-group {
                @include col(5, 13);
                font-size: 15px;
                font-weight: 500;
                color: $gray-900;
            }
            &--scoped {
                text-align: right;
                .fx-data-table-head-title {
                    text-align: right;
                }
            }
        }
    }

    &--engagement {
        #{$this}-row {
            @include grid(24);
        }
        #{$this}-col {
            &--family-group {
                @include col(4, 24);
                font-weight: 600;
                font-size: 15px;
                color: $gray-900;
            }
            &--prior-year,
            &--selected-year {
                @include col(4, 24);
                text-align: right;
            }

            &--engaged,
            &--engagement,
            &--matter-partner,
            &--matter-manager {
                @include col(2, 24);
            }
            &--engaged {
                text-align: right;
                .fx-data-table-head-title {
                    text-align: right;
                }
            }
            &--scheduled-date {
                @include col(2, 24);
            }
            &--status {
                @include col(2, 24);
            }
        }
    }

    &-col {
        &--split {
            display: flex;
            flex-wrap: wrap;
        }
        &--sub {
            flex: 1;
            align-items: center;
            display: flex;
            justify-content: flex-start;
        }
        &-sort-icon {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            margin-left: 4px;
            transform: rotate(180deg);
            transition: all 0.2s ease;
            cursor: pointer;

            &:hover {
                background-color: #ccc;
            }

            &--is-sorted {
                transform: rotate(0deg);
                color: $white;
                background-color: $brand-primary;

                &:hover {
                    background-color: darken($brand-primary, 5%);
                }
            }
        }
        &--details {
            font-weight: bold;
            color: $brand-primary;
        }
        &--family-group {
            padding: 0 12px 0 50px !important;
        }
    }
    &-empty {
        padding: 24px;
        text-align: center;
        p {
            font-size: 0.95rem;
            color: $gray-600;
        }
    }

    &-value {
        font-size: $font-size;
        &--details {
            color: $brand-primary;
            font-weight: bold;
            cursor: pointer;
            &:hover {
                color: darken($brand-primary, 5%);
            }
        }
        &--upper-case {
            text-transform: uppercase;
        }
        &--summary {
            // TODO: remove important
            width: 200px !important;
            height: 65px !important;
            // TODO replace element w/ class
            input {
                padding: $gutter-size * 0.5;
                text-align: left;
            }
        }
        &--description {
            // TODO: remove important
            width: 400px !important;
            height: 65px !important;
            // TODO replace element w/ class
            textarea {
                padding: $gutter-size * 0.5;
                padding-top: $gutter-size * 0.75;
                text-align: left;
                outline: 0;
            }
        }

        &--advancedOptions {
            flex-basis: 180px;
            .product-configuration-options-column {
                flex-basis: 180px !important;
            }
        }
    }

    &-item {
        font-weight: $font-weight-bold;
        color: $gray-700;
    }
}

@include breakpoint("hd") {
    .fx-data-table {
        &-head {
            &-title {
                font-size: $font-size !important;
            }
        }
    }
}
