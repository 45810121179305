/*
    ===================================================================================================================
    Name: fx-single-multi-picker
    Type: component
    Scope: JST pages
    Description:
    Reusable single-multi component for selection of single/multiple months in year

    Naming convention (BEM like)
    BLOCK: fx-block / fx-block-descriptor
    ELEMENTS: fx-block-element / fx-block-descriptor-element
    MODIFIERS: fx-block-element--modifier / fx-block-descriptor-element--modifier

    Examples
    fx-btn / fx-btn-primary / fx-btn-seconday / fx-btn-teriary
    fx-btn-icon / fx-btn-primary-icon / fx-btn-secondary-icon / fx-btn-teriary-icon
    fx-btn--is-active / fx-btn-primary--is-active / fx-btn-seconday--is-active / fx-btn-teriary--is-active
    ===================================================================================================================
*/

/*
    ===================================================================================================================
    Imports
    SASS @imports area
    ===================================================================================================================
*/

// @import 'some-resource';
// ...

/*
    ===================================================================================================================
    Config
    Local vars, placeholders, mixins and functions
    Note: Reference global vars, placeholders, mixins and functions before writing your own
    ===================================================================================================================
*/

$picker-default-bgcolor: #90a4ae;
$picker-active-bgcolor: #095fdc;
$picker-focus-bgcolor: darken(#095fdc, 20%);
$picker-color: $white;
$picker-toggle-height: 42px;
$picker-line-height: 40px;
$picker-item-width: 50px;
$picker-item-default-bg-color: $white;
$picker-item-color: #7d8386;

/*
    ===================================================================================================================
    Base
    Common styles that apply grouped selectors i.e. .fx-block, .fx-block-descriptor {}
    Note: You may use [class*=""], [class^=""] etc.. but be careful
    ===================================================================================================================
*/

.fx-single-multi-picker {
    position: relative;
    height: $picker-toggle-height;
    line-height: $picker-toggle-height;
    &-items {
        position: relative;
        z-index: $z-index-10;
        display: none;
        max-height: 0;
        height: $picker-toggle-height;
        margin-left: 2px;
        line-height: $picker-toggle-height;
        overflow: hidden;
        background-color: $white;

        &--is-visible {
            position: absolute;
            top: 0;
            left: 123px;
            display: flex;
            width: 100%;
            height: 100%;
            height: $picker-toggle-height;
            line-height: $picker-toggle-height;
            max-height: 1000px;
            overflow: visible;
        }
    }
    &-item {
        display: block;
        float: left;
        height: $picker-toggle-height;
        line-height: $picker-toggle-height;

        &--is-selected {
            background-color: $picker-active-bgcolor;
            color: $picker-color;
        }
    }

    &-toggle {
        position: relative;
        display: block;
        float: left;
        height: $picker-toggle-height;
        height: 42px;
        margin-left: -1px;
        padding: 0 6px;
        border: 0;
        line-height: $picker-toggle-height;
        font-size: 14px;
        cursor: pointer;
        color: #7d8386;
        text-align: center;
        border: 1px solid #90a4ae;
        letter-spacing: 1px;
        font-weight: bold;
        &--is-active {
            background-color: $picker-active-bgcolor;
            &:after {
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translateX(-50%);
                display: block;
                width: 1px;
                height: 24px;
                background-color: $picker-active-bgcolor;
                content: "";
            }
        }
        &:focus,
        &:hover {
            background-color: $picker-focus-bgcolor;
        }

        &-disabled {
            cursor: default;
            opacity: 0.5;
            pointer-events: none;
        }
    }

    &-summary {
        position: absolute;
        padding-left: 2px;
        font-size: 14px;
        font-weight: 600;
        color: #6c757d;
    }

    &-checkbox {
        appearance: none;
        -webkit-appearance: none; // TODO use autoprefixer

        &:hover + label,
        &:focus + label {
            background-color: $picker-default-bgcolor;
            color: $picker-color;
        }

        &:checked + label {
            background-color: $picker-active-bgcolor;
            color: #fff;

            &:hover,
            &:focus {
                background-color: $picker-focus-bgcolor;
                color: #fff;
            }
        }

        &:checked {
            &:hover + label,
            &:focus + label {
                background-color: $picker-focus-bgcolor;
                color: #fff;
            }
        }
    }

    &-label,
    &-close {
        position: relative;
        display: block;
        width: $picker-item-width;
        float: left;
        font-size: 14px;
        padding: 0 12px;
        line-height: $picker-line-height;
        background-color: $picker-item-default-bg-color;
        color: $picker-item-color;
        text-align: center;
        cursor: pointer;
        border: 1px solid #90a4ae;
        border-right: none;
        &:focus,
        &:hover {
            background-color: $picker-focus-bgcolor;
            color: $picker-color;
        }

        &-disabled {
            cursor: default;
            opacity: 0.5;
            pointer-events: none;
        }
    }

    &-label {
        &--is-selected {
            background-color: $picker-active-bgcolor;
            color: $picker-color;

            &:focus,
            &:hover {
                background-color: $picker-focus-bgcolor;
                color: $picker-color;
            }
        }

        &-disabled {
            cursor: default;
            opacity: 0.5;
            pointer-events: none;
        }
    }

    &-close {
        border-right: 1px solid #90a4ae;
        height: 42px;
        padding: 0 20px;
        background-color: #d9e1e4;
    }
}

.fx-single-multi-picker > .fx-single-multi-picker-item {
    &:nth-child(2) {
        .fx-single-multi-picker-label {
            border-right: 1px solid #90a4ae;
        }
    }
}
