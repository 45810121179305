/* 
    ===================================================================================================================
    Name: select
    Type: material custom component
    Scope: all
    Description: Dropdown for form options
    ===================================================================================================================
*/

/* 
    ===================================================================================================================
    Config
    Local vars, placeholders, mixins and functions
    Note: Reference global vars by assigning to local variable to prevent overriding their values, use global
    placeholders, mixins and functions before writing your own
    ===================================================================================================================
*/

// $some-local-var: $some-global-var

/* 
    ===================================================================================================================
    Core
    The actual rule declaration with nested elements and modifiers
    ===================================================================================================================
*/

.mat-form-field-appearance-outline {
    .mat-form-field-wrapper {
        margin: 0 !important;
    }
    .mat-form-field-flex {
        margin: 0 !important;
        padding: 0 !important;
    }
    .mat-form-field-outline {
        top: 0 !important;
    }
    .mat-form-field-outline-start,
    .mat-form-field-outline-gap,
    .mat-form-field-outline-end {
        border: 0 !important;
        border-radius: 0 !important;
    }
    .mat-form-field-infix {
        display: block !important;
        min-width: unset !important;
        padding: 0 !important;
        margin: 0 !important;
        border: 0 !important;

        input {
            top: 0 !important;
            margin: 0 !important;
            height: 42px !important;
            line-height: 42px !important;
        }
    }
    .mat-form-field {
        display: block !important;
    }
    .mat-select {
        display: block !important;
        padding: 0 !important;
        border: 0 !important;
    }
    .mat-select-trigger {
        height: auto !important;
        padding: 0 !important;
        line-height: unset !important;
        width: 100% !important;
    }
    // TODO remove mat-select in favor of fx-select or another comp library select
    // HACK: ng13 broke the height of the mat-selects
    .mat-select-trigger {
        display: flex;
        height: 40px;
    }

    .mat-select-value {
        display: block !important;
        max-width: unset !important;
        height: auto !important;
        padding: 0 2rem 0 1rem !important;
        line-height: 42px !important;
        height: 42px !important;
        background-color: white;
    }
    .mat-select-arrow-wrapper {
        display: none !important;
    }

    .mat-input-element {
        vertical-align: unset;
    }
}

.mat-select-placeholder,
.mat-select-value,
.mat-input-element,
.mat-select-trigger {
    background: white;
    color: $gray-700;
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 0 0.5rem;
}
.mat-select-alt {
    background: $gray-300;
}
.mat-form-field-appearance-outline .mat-form-field-outline {
    display: none;
}
.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
    transform: none;
}
.mat-form-field-wrapper,
.mat-form-field-appearance-outline .mat-form-field-flex,
.mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0;
    padding: 0;
}

/*  
    ===================================================================================================================
    States
    Media queries zone / Modifier zone
    Note: media queries should be avoided in components where possible as they are deemed part of layout styles, there
    are exceptions to this rule - if providing media queries in components please commment why
    ===================================================================================================================
*/

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple),
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
    background: #d43f27 !important;
    color: white !important;
}
.mat-option:hover:not(.mat-option-disabled),
.mat-option:focus:not(.mat-option-disabled) {
    background: #dee2e6 !important;
}
.mat-select-panel {
    //   min-width: 200px !important;
}

.mat-form-field-hide-placeholder .mat-select-placeholder {
    background: transparent;
}
.mat-form-field-empty.mat-form-field-label {
    transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00103px) !important;
}
