/*
    ===================================================================================================================
    Name: Core mixins manifest for FX-UI
    Type: mixins
    Scope: all
    Description:
    This file imports all mixins required by FX-UI style guide
    ===================================================================================================================
*/

// * Check if this is being imported by FXUI
@import 'breakpoints';

// * Replace with flex in UI Refresh
@import 'grid';

@import 'table';
