/*
    ===================================================================================================================
    Mixin 1:
    Name: Breakpoint
    Type: mixins
    Scope: all
    Description:
    Provides media queries for min widths passed in as parameters
    note: $size parameter should be a breakpoint defined in global _variables.scss

   
   =============================================================
   */
$screen-mobile: 320px !default; // screen 100%
$screen-tablet: 768px !default; // screen 100%
$screen-laptop: 1024px !default; // screen 100%
$screen-desktop: 1280px !default; // screen 1200px ?
$screen-hd: 1920px !default; // screen 1440px ?
$grid-breakpoints: (
    mobile: $screen-mobile,
    tablet: $screen-tablet,
    laptop: $screen-laptop,
    desktop: $screen-desktop,
    hd: $screen-hd,
) !default;

@mixin breakpoint($breakpoint) {
    @if map-has-key($grid-breakpoints, $breakpoint) {
        @media only screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
            @content;
        }
    } @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "+"Available breakpoints are: #{map-keys($grid-breakpoints)}.";
    }
}
