/*  
    ===================================================================================================================
    Name: fx-progress
    Type: component
    Scope: JST pages
    Description: 
    Reusable progress indicator
    
    Naming convention (BEM like)
    BLOCK: fx-block / fx-block-descriptor
    ELEMENTS: fx-block-element / fx-block-descriptor-element
    MODIFIERS: fx-block-element--modifier / fx-block-descriptor-element--modifier

    Examples
    fx-btn / fx-btn-primary / fx-btn-seconday / fx-btn-teriary
    fx-btn-icon / fx-btn-primary-icon / fx-btn-secondary-icon / fx-btn-teriary-icon
    fx-btn--is-active / fx-btn-primary--is-active / fx-btn-seconday--is-active / fx-btn-teriary--is-active
    ===================================================================================================================
*/

/*  
    ===================================================================================================================
    Imports 
    SASS @imports area
    ===================================================================================================================
*/

// @import 'some-resource';
// ...

/*  
    ===================================================================================================================
    Config
    Local vars, placeholders, mixins and functions
    Note: Reference global vars, placeholders, mixins and functions before writing your own
    ===================================================================================================================
*/

$triangle-colour: #566268;
$progress-base-colour: #82949d;
$progress-font-size: $font-size;
$progress-step-default-colour: #c2cdd3;
$progress-step-focus-colour: #566268;
$progress-step-border-active-colour: #839198;
$progress-step-font-size: px-to-rem(14px);
$progress-summary-margin: 0 2px;
$progress-summary-padding: 0 0 6px 0;
$progress-step-margin: 0 2px;
$progress-step-padding: 6px 0 0 0;

/*  
    ===================================================================================================================
    Base
    Common styles that apply grouped selectors i.e. .fx-block, .fx-block-descriptor {}
    Note: You may use [class*=""], [class^=""] etc.. but be careful
    ===================================================================================================================
*/

:host {
    display: block;
    width: 100%;
}

.fx-progress {
    position: relative;
    color: #acbcc3;
    font-size: 16px;

    &-stage {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
    }

    &-summary {
        width: 100%;
        margin: $progress-summary-margin;
        padding: $progress-summary-padding;
        color: $progress-base-colour;
    }

    &-step {
        position: relative;
        margin: 0 2px;
        border-top: 6px solid #c2cdd3;
        font-size: 14px;
        padding-top: 6px;
        color: #65737a;
        flex: 1;
        line-height: 1.1rem;

        cursor: pointer;

        &:hover,
        &:focus {
            border-color: $progress-step-border-active-colour !important;
            color: #566268;
            font-weight: 600;
        }

        &--valid {
            cursor: pointer;
        }

        &--active {
            border-color: $brand-success;
            color: black;
            font-weight: bold;

            &:hover,
            &:focus {
                border-color: $progress-step-border-active-colour !important;
                color: #566268 !important;
                font-weight: 600;
            }
        }
    }
}

/*  
    ===================================================================================================================
    States
    Media queries zone
    Note: media queries should be avoided in components where possible as they are deemed part of layout styles, there
    are exceptions to this rule - if providing media queries in components please commment why
    ===================================================================================================================
*/
