/* 
    ===================================================================================================================
    Name: autocomplete
    Type: material custom component
    Scope: all
    Description: Search input that autocompletes
    ===================================================================================================================
*/

/* 
    ===================================================================================================================
    Config
    Local vars, placeholders, mixins and functions
    Note: Reference global vars by assigning to local variable to prevent overriding their values, use global
    placeholders, mixins and functions before writing your own
    ===================================================================================================================
*/

// $some-local-var: $some-global-var

/* 
    ===================================================================================================================
    Core
    The actual rule declaration with nested elements and modifiers
    ===================================================================================================================
*/

mat-autocomplete {
    width: 100%;
    .mat-option-text {
        img {
            vertical-align: middle;
            margin-right: 8px;
        }
    }
}
