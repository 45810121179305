/*
    ===================================================================================================================
    Name: Themes Manifest
    Type: Manifest
    Scope: theme
    Description:
    Theme manifest, in this case this is simply a place to put an override for material, bootstrap etc
    ===================================================================================================================
*/

// * Theme variables

// Paths
$asset-path: "";
$brand-image: "";

// Fonts
$font-family-body: "Source Sans Pro", sans-serif;
$font-family-import-body-path: "https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400&display=swap";
$font-family-heading: "";
$font-family-import-primary: "Source Sans Pro", sans-serif;
$font-family-import-primary-path: "https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400&display=swap";

// * Component imports
@import "./autocomplete";
@import "./button";
@import "./card";
@import "./checkbox";
@import "./checkbutton";
@import "./datepicker";
@import "./form";
@import "./icon";
@import "./input";
@import "./modal";
@import "./radio";
@import "./select";
@import "./tooltip";
