.fx-page-header {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    &-row {
        flex: 0 0 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: $gutter-size * 3 $gutter-size;
        height: 68px;
        &--scope {
            background-color: $gray-200;
        }
        &--family-group {
            background-color: $white;
        }
    }
    &-name,
    &-settings,
    &-progress {
        flex: 1 1 auto;
    }
    &-name {
        flex-basis: 25%;
        flex-grow: 0;
    }
    &-name-title {
        margin: 0;
    }
    &-name-count {
        font-size: px-to-rem(18px);
        color: $gray-600;
    }
    &-title {
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0;
    }

    &-subtitle {
        text-transform: capitalize;
        font-size: $font-size * 0.875;
        color: $gray-600;
        margin-bottom: 0;
    }

    &-search {
        width: 100%;
    }

    &-settings {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-end;
        min-height: 58px;
        // TODO MERGE WITH BTN MIXIN
        .fx-btn-group {
            margin-right: 4px;
            height: 25px;
        }
        .fx-btn {
            margin: 0 5px 0px 5px;
            display: block;
            line-height: 15px;
            line-height: 15px !important;
            border-radius: 3px;
        }
        .fx-btn--primary {
            border: 1px solid blue !important;
        }
    }

    // NOTE: All other divs declared within page header should live in layout
    //because the only constant is heading/title, everything else is specific to layout
}
