/*
    ===================================================================================================================

    Overwrite fx-ui components
    Keep this very limited, always source if an issue can be fixed with fx-ui first

    ===================================================================================================================
*/
.app-jst {
    // Overwrite buttons until we use V1 beta and have support for sm/md/lg
    .fx-btn {
        padding: 0 1rem;
        line-height: 30px;
        text-transform: inherit;
        margin: 0 0.25rem;
        &:disabled {
            cursor: default;
            background: inherit;
            color: inherit;
            border: 1px solid $brand-secondary;
        }
    }

    // Overwrite sizing and hide bottom border of forms/accordions to match JST UI
    .fx-form-input {
        padding: 0.35rem 0;
        border-bottom: 1px solid transparent;
        border-radius: 0;
        &:hover,
        &:focus {
            border-bottom: 1px solid transparent;
        }
    }

    // Overwrite is-mandatory asterisk for dot
    .is-mandatory:before {
        content: "";
        border-radius: 50%;
        width: 6px;
        height: 6px;
        background-color: #d43d27;
    }

    // Add spacing to form labels
    .fx-form-label {
        padding: $gutter-size / 2 0;
    }

    // Match inputs to design
    .fx-form-input {
        padding: 0.7rem 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        &:hover,
        &:focus {
            border-bottom: 1px solid rgba(0, 0, 0, 0.5);
        }
        &.has-error {
            border-bottom: 1px solid #d43d27;
        }
    }

    // Remove force uppercase of tab items
    .fx-tabs {
        &-item {
            position: relative;
            text-transform: initial;
        }
    }

    // Overwrite material checkbox - TODO: Replace with fx-checkbox
    .fx-checkbox.fx-checkbox--engaged.mat-checkbox-checked {
        .mat-checkbox-background {
            background-color: $brand-success;
        }
    }

    // TODO: Fix this once FXUI 2.0 version has been implemented

    .fx-select {
        background: transparent !important;
        &-option {
            padding: $gutter-size;
            &:hover {
                background: $brand-primary;
            }
        }
        // Don't allow disabled options to be shown
        &--disabled {
            opacity: 0.5;
            .fx-accordion {
                &-toggle {
                    cursor: default;
                    &-icon {
                        transform: rotate(0deg);
                    }
                }
                &-content {
                    display: none;
                }
            }
        }

        .fx-accordion {
            border: none;
            &-toggle {
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                background: transparent;
                color: $brand-secondary;
                &--has-gutters {
                    padding: $gutter-size * 0.5 0;
                }
                &-icon {
                    font-size: $font-size !important;
                }
            }
            &-content {
                background: white;
                &--is-visible {
                    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
                    text-align: left;
                    overflow-y: auto;
                    max-height: 18rem;
                    min-width: 100px;
                    z-index: $z-index-above-all;
                }
            }
        }

        // Create second select element styling that uses new FXUI curved and bordered accordion styling
        &--curved {
            .fx-accordion {
                &-toggle {
                    font-weight: bold;
                    border-radius: 20rem;
                    border: 1px solid $brand-primary;
                    padding: $gutter-size * 0.75;
                    background: transparent;
                    color: $brand-primary;
                }
            }
        }
    }

    // Styling for accordion
    // TODO Move to accordion component
    .fx-accordion {
        border: 1px solid #dee2e6;
        &-toggle {
            border: none;
            background: #e9ecef;
            &-icon {
                font-size: $font-size * 1.5 !important;
            }
        }
    }
}
