/*
    ===================================================================================================================
    Name: fx-table
    Type: component
    Scope: JST pages
    Description:
    Reusable custom table component using divs
    ===================================================================================================================
*/

/*
    ===================================================================================================================
    Config
    Local vars, placeholders, mixins and functions
    Note: Reference global vars, placeholders, mixins and functions before writing your own
    ===================================================================================================================
*/

%title-icon {
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    width: 40px;
    height: 40px;
    text-align: center;
}

$table-head-bg: #e1e6e9;
$table-head-border-top-color: #b8c5cb;
$table-head-border-bottom-color: #c6d0d4;
$table-head-title-font-size: $font-size * 0.85;
$table-head-control-font-size: $font-size * 0.85;
$table-head-title-color: $gray-800;
$table-head-material-icon-properties-size: 40px;
$table-head-line-height: 40px;
$table-head-subtitle-font-size: $font-size * 0.85;
$table-head-subtitle-color: darken(#949fa4, 10%);
$table-summary-color: darken(#d3dbdf, 20%);
$table-summary-bg: darken(#e1e6e9, 40%);
$table-summary-line-height: 42px;

$table-body-line-height: 40px;
$table-body-color: $gray-400;
$table-row-padding: 6px;
$table-row-height: 36px;
$table-uppercase: uppercase;
$table-capitalize: capitalize;
$table-line-height: 40px;

/*
    ===================================================================================================================
    Base
    Common styles that apply grouped selectors i.e. .fx-block, .fx-block-descriptor {}
    Note: You may use [class*=""], [class^=""] etc.. but be careful
    ===================================================================================================================
*/

@mixin fx-table {
    font-size: $font-size * 0.85;
    padding: 0 $gutter-size/2;

    &-head {
        border-top: 1px solid $table-head-border-top-color; // TODO redefine to simplify
        border-bottom: 1px solid $table-head-border-bottom-color; // TODO redefine to simplify
        background-color: $table-head-bg;
        &-title {
            @extend %ellipsis;
            position: relative;
            display: flex;
            align-items: center;
            font-size: $table-head-title-font-size;
            line-height: $table-head-line-height;
            text-transform: $table-uppercase;
            color: $table-head-title-color;
            text-align: left;
            &--has-filter {
                &:after {
                    @extend %title-icon;
                    font-family: "Material Icons";
                    content: "\e152";
                    line-height: $table-head-line-height;
                    font-size: $font-size * 1.5;
                    color: $gray-500;
                }
            }
        }
        &-subtitle {
            display: block;
            font-size: $table-head-subtitle-font-size;
            line-height: $table-line-height;
            text-transform: $table-capitalize;
            color: $table-head-subtitle-color;
        }
        &-control {
            display: block;
            padding: 0 12px; // TODO arbitrary
            border: 1px solid transparent;
            line-height: $table-head-line-height; // TODO arbitrary
            font-size: $table-head-control-font-size;
            font-weight: 600; // TODO arbitrary
        }
    }
    &-summary {
        border-bottom: 1px solid $table-head-border-bottom-color; // TODO redefine to simplify
        line-height: $table-summary-line-height;
        color: $table-summary-color;
        background-color: $table-head-bg;
    }
    &-body {
        line-height: unset;
        font-size: $font-size * 1;
    }
}
