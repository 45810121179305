/* 
    ===================================================================================================================
    Name: checkbutton
    Type: material custom component - NOTE depends on material _checkbox
    Scope: all
    Description: Checkbutton for forms and tables
    ===================================================================================================================
*/

/* 
    ===================================================================================================================
    Config
    Local vars, placeholders, mixins and functions
    Note: Reference global vars by assigning to local variable to prevent overriding their values, use global
    placeholders, mixins and functions before writing your own
    ===================================================================================================================
*/

// $some-local-var: $some-global-var

$checkbtn-disabled-bgcolor: #90a4ae;
$checkbtn-hover-bgcolor: #90a4ae;
//$checkbtn-active-bgcolor: $brand-jst-blue;
$checkbtn-active-bgcolor: #095fdc;
$checkbtn-focus-bgcolor: darken(#095fdc, 10%);

/* 
    ===================================================================================================================
    Core
    The actual rule declaration with nested elements and modifiers
    ===================================================================================================================
*/

.fx-checkbutton {
    label {
        position: relative;
        &.mat-checkbox-layout {
            .mat-checkbox-inner-container {
                background: white;
                height: 29px !important;
                width: 56px !important;
                border-radius: 40px !important;
                .mat-checkbox-input {
                }
                .mat-checkbox-ripple {
                    display: none !important;
                }
                .mat-checkbox-background,
                .mat-checkbox-frame {
                    width: 56px !important;
                    border-radius: 40px !important;
                }
                .mat-checkbox-frame {
                    border-color: #90a4ae;
                }
                .mat-checkbox-background {
                    //background-color: $checkbtn-bgcolor;
                    .mat-checkbox-checkmark {
                        left: 16px !important;
                        width: 24px !important;
                        top: 2px !important;
                        // fill: $brand-jst-blue;
                        fill: rgba(9, 95, 219, 1);
                    }
                }
            }
            &:hover {
                .mat-checkbox-frame {
                    border-color: #90a4ae;
                    background-color: #90a4ae;
                }
                .mat-checkbox-label {
                    color: white !important;
                }
            }
            .mat-checkbox-label {
                position: absolute;
                color: #6c757d;
                left: 14px;
                top: 1px;
            }
        }
    }
    &.mat-checkbox-checked {
        .mat-checkbox-label {
            visibility: hidden;
        }
    }
}

.fx-checkbutton {
    &.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background,
    &.mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
        background-color: $checkbtn-disabled-bgcolor;
    }
}
