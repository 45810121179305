/*
    ===================================================================================================================
    Name: modal
    Type: material custom component
    Scope: all
    Description: Popup window to show more information
    ===================================================================================================================
*/

/*
    ===================================================================================================================
    Config
    Local vars, placeholders, mixins and functions
    Note: Reference global vars by assigning to local variable to prevent overriding their values, use global
    placeholders, mixins and functions before writing your own
    ===================================================================================================================
*/

// $some-local-var: $some-global-var

/*
    ===================================================================================================================
    Core
    The actual rule declaration with nested elements and modifiers
    ===================================================================================================================
*/

.mat-dialog-container {
    background: white !important;
    border-radius: 0 !important;
    max-height: 90vh !important;
}
