/*
    ===================================================================================================================
    Name: chips
    Type: material custom component
    Scope: all
    Description: show list or set of items
    ===================================================================================================================
*/

/*  
    ===================================================================================================================
    Config
    Local vars, placeholders, mixins and functions
    Note: Reference global vars, placeholders, mixins and functions before writing your own
    ===================================================================================================================
*/

// Status colors
$brand-indicator-pink: #ed4562;
$brand-indicator-blue: #0672bc;
$brand-indicator-orange: #ff934a;
$brand-indicator-yellow: #f9c215;
$brand-indicator-drab-green: #92bc35;
$brand-indicator-green: #00b25d;
$brand-indicator-purple: #8e5dc1;

/*
    ===================================================================================================================
    Core
    The actual rule declaration with nested elements and modifiers
    ===================================================================================================================
*/

// * fx-chip is imported by FX-UI, only color styling belongs here

.fx-chip {
    // TODO: Uncomment in UI refresh
    // background-color: $brand-primary;
    color: $gray-100;
    i {
        cursor: pointer;
    }
    &--approved {
        background: $brand-indicator-green;
    }
    &--manual {
        background: $brand-indicator-blue;
    }
    &--not-created {
        background: $gray-500;
    }
    &--not-required {
        color: $gray-500;
    }
    &--not-scoped {
        background: $brand-indicator-pink;
    }
    &--created {
        background: $brand-indicator-orange;
    }
    &--scoping {
        background: $brand-indicator-orange;
    }
    &--scoped {
        background: $brand-indicator-yellow;
    }
    &--open {
        background: $brand-indicator-yellow;
    }
    &--draft {
        background: $brand-indicator-yellow;
    }
    &--scheduled {
        background: $brand-indicator-blue;
    }
    &--scheduled-manual {
        background: $brand-indicator-blue;
    }
    &--sent {
        background: $brand-indicator-purple;
    }
    &--sendFailed {
        background: $brand-indicator-pink;
    }
    &--signed {
        background: $gray-900;
    }
    &--tasked {
        background: $brand-indicator-blue;
    }
    &--crm-opportunity {
        background: $brand-indicator-blue;
    }
}
