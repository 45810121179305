/*
    ===================================================================================================================
    Name:  Listing pages Layout
    Type: Layout
    Scope: A page layout
    Description:
    Single page layout. Only affects one page. Uses minimal fx-l classes and allows selecting fx-components
    directly as long as the specificity is max 0.2.0 (two classes - https://specificity.keegan.st/)
    Note: selectors should not pierce the fx-component base element into the children - internal layout is a
    concern of the components

    ===================================================================================================================
*/

/*
    ===================================================================================================================
    Config
    Local vars, placeholders, mixins and functions
    Note: Reference global vars, placeholders, mixins and functions before writing your own
    ===================================================================================================================
*/

$table-header-bg: #e1e6e9;
$table-header-txt: #65737a;
$table-cell-bg: #f9f9f9;
$table-border-light: #d7dee2;
$table-border-med: #65737a;
$table-row-hover: #ebeef0;

/*
    ===================================================================================================================
    Core
    The actual rule declaration with nested elements and modifiers
    ===================================================================================================================
*/

.fx-l-product {
    &-tabs {
        display: flex;
        position: sticky;
        top: 80px;
        align-items: flex-end;
        justify-content: center;
    }
    &-actions {
        &-navigation {
            // TODO - this component needs to flex
            position: absolute;
            right: 16px;
            top: -54px;
            text-align: right;
            &-button {
                margin: 0 $gutter-size / 3;
            }
        }
    }
    &-list {
        display: block;
        width: 100%;

        &-layout {
            display: flex;
            &-search {
                flex: 0 0 336px;
            }
            &-content {
                overflow-x: auto;
            }
            &-subtitle {
                margin: 0;
                padding: 24px 24px 0 24px;
                font-size: 14px;
                color: $gray-500;
            }

            &-title {
                margin: 0;
                padding: 0 24px 24px 24px;
            }
        }
        &-table {
            position: relative;
            min-width: calc(100vw - 418px);
            min-height: calc(100vh - (136px + 190px));
            &-empty {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
                &-paragraph {
                    font-size: px-to-rem(14px);
                    margin-top: 0;
                }
            }
            &--empty-cell {
                flex: 0 0 180px;
                border-right: 1px solid $table-border-light;
            }
            .fx-table {
                &-head {
                    display: flex;
                    flex-wrap: nowrap;
                    flex-grow: 1;

                    &-group {
                        flex-grow: 1;
                        padding: $gutter-size * 0.25;
                        font-size: 0.8rem;
                        font-size: px-to-rem(13px);
                        line-height: 1.1rem;
                        text-transform: none;
                        font-weight: 700;
                        overflow: hidden;
                        color: black;
                        cursor: default;
                    }

                    &-title {
                        flex-grow: 1;
                        font-size: px-to-rem(13px);
                        line-height: 1.1rem;
                        text-transform: none;
                        font-weight: 700;
                        padding-left: $gutter-size * 0.25;
                        overflow: hidden;
                        cursor: default;
                    }
                    &-row {
                        display: flex;
                        flex-direction: row;
                        flex-grow: 1;
                        flex-wrap: nowrap;
                        align-items: initial;
                        min-height: 62px;
                        overflow: unset;
                        background: $table-header-bg;
                        color: $table-header-txt;
                    }
                    &-cell {
                        float: left;
                        display: flex;
                        justify-content: center;
                        background: $table-header-bg;

                        &--multi-option {
                            flex: 0 0 180px;
                            width: 180px;
                        }

                        &--number,
                        &--boolean {
                            flex: 0 0 120px;
                            width: 120px;
                        }
                    }
                }
                &-body {
                    display: flex;
                    flex-wrap: wrap;
                    flex-grow: 1;
                    &-row {
                        display: inline-flex;
                        flex-grow: 1;
                        flex-wrap: nowrap;
                        min-width: calc(100vw - 418px);
                        min-height: 70px;
                        border-bottom: 1px solid $table-border-light;
                        &:not(.fx-select-all) {
                            &:hover {
                                background: $table-row-hover !important;
                                .fx-table-body-cell {
                                    background: $table-row-hover !important;
                                }
                            }
                        }
                    }
                    &-cell {
                        display: flex;
                        float: left;
                        align-items: center;
                        &:first-child {
                            flex: 0 0 180px;
                            text-overflow: ellipsis;
                            justify-content: flex-end;
                            overflow: hidden;
                            width: 180px;
                            padding: 5px 5px;
                            margin-right: 8px;
                            border-right: 1px solid $table-border-light;
                            line-height: 1.2rem;
                            z-index: 40;
                            cursor: default;
                            background-color: $table-cell-bg;
                        }

                        &--multi-option {
                            flex: 0 0 180px;
                            width: 180px;
                        }

                        &--boolean,
                        &--number,
                        &--text {
                            flex: 0 0 120px;
                            width: 120px;
                        }
                    }
                }
            }
        }
    }

    &-columns {
        width: 100%;
        .fx-tabs {
            border-bottom: 1px solid $gray-500;
            &-item {
                padding: 0.5rem 2rem;
            }
        }
        .fx-tabs-heading {
            font-size: 16px;
        }
    }

    &-table {
        position: relative;
        min-width: calc(100vw - 418px);
        min-height: calc(100vh - (136px + 190px));
        .fx-checkbox {
            z-index: 0;
        }
        .fx-select-all {
            position: relative;
            z-index: 8;
            opacity: 0.4;
            border-bottom: 2px solid #d7dee2;
        }
    }
}
