/* 
    ===================================================================================================================
    Name: icons
    Type: material custom component
    Scope: all
    Description: Material icons
    ===================================================================================================================
*/

/* 
    ===================================================================================================================
    Config
    Local vars, placeholders, mixins and functions
    Note: Reference global vars by assigning to local variable to prevent overriding their values, use global
    placeholders, mixins and functions before writing your own
    ===================================================================================================================
*/

// $some-local-var: $some-global-var

/* 
    ===================================================================================================================
    Core
    The actual rule declaration with nested elements and modifiers
    ===================================================================================================================
*/

.fx-icon {
    &--warning {
        color: $brand-indicator-orange;
    }
    &--danger {
        color: $brand-danger;
    }
    &--success {
        color: $brand-success;
    }
    &--info {
        color: $brand-indicator-orange;
    }
}
