/* 
    ===================================================================================================================
    Name: card
    Type: material custom component
    Scope: all
    Description: Container for information
    ===================================================================================================================
*/

/* 
    ===================================================================================================================
    Config
    Local vars, placeholders, mixins and functions
    Note: Reference global vars by assigning to local variable to prevent overriding their values, use global
    placeholders, mixins and functions before writing your own
    ===================================================================================================================
*/

// $some-local-var: $some-global-var

/* 
    ===================================================================================================================
    Core
    The actual rule declaration with nested elements and modifiers
    ===================================================================================================================
*/

.mat-card {
    margin: 1rem 0;
    padding: 0 !important; //alt to important?
    box-shadow: none !important;
    border-radius: 0 !important;
    border: 1px solid $gray-300;
    &-header {
        background: $gray-200;
        padding: 1rem;
        &--move {
            cursor: move;
        }
        &-text {
            margin: 0 !important;
            width: 100%;
            display: flex !important;
            &-close {
                flex: 0 0 49% !important;
                text-align: right;
                mat-icon {
                    cursor: pointer;
                }
            }
            .mat-card-title {
                // flex: 0 50% !important;
                margin: 0 !important;
            }
        }
    }
}
