/* 
    ===================================================================================================================
    Name: config Page LAYOUT
    Type: Layout
    Scope: config pages 
    Description: Scope to extend across all config pages 
    ===================================================================================================================
*/

.fx-l-config {
    display: block;
    width: 100%;
    background-color: $gray-200;

    .fx-page-header {
        &-content {
            align-items: flex-end;

            &--steps {
                background-color: $gray-400;
            }
        }

        .fx-progress {
            flex: 1 0 50%;

            &-current-stage {
                flex: 0 0 100%;
                margin-bottom: 0;
            }
        }

        &-pagination {
            flex: 1 1 auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            align-items: center;
        }
    }

    &-options {
        position: relative;
        border-bottom: 1px solid $gray-500;
        background-color: $gray-100;
    }

    &-filter {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: $gutter-size;
        background-color: $gray-100;

        &-settings {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            .fx-btn-group {
                flex: 1 1 auto;
                margin: $gutter-size * 0.5;
            }
        }
    }
    &-legend {
        display: flex;
        justify-content: flex-start;
        padding: 12px 24px;
        &-item {
            position: relative;
            font-size: 12px;
            i {
                left: -10px;
                top: 5px;
                right: unset;
            }
        }
    }
    &-table {
        width: 100%;
    }
}

@include breakpoint("desktop") {
    .fx-l-config {
        .fx-tabs {
            &-item {
                font-size: $font-size;
            }
        }
    }
}
