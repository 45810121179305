/* 
    ===================================================================================================================
    Name: datepicker
    Type: material custom component
    Scope: all
    Description: Datepicker UI adjustments
    ===================================================================================================================
*/

.mat-calendar {
    &-period-button {
        background: $brand-primary !important;
    }
    &-arrow {
        border-top-color: white !important;
    }
    &-body-selected {
        background-color: $brand-primary !important;
    }
}
