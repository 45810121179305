/* 
    ===================================================================================================================
    Name: Layouts Manifest
    Type: Manifest
    Scope: layouts
    Description:
    Layouts manifest, no order is necessary here, however for neatness separate out by order of pages 
    in sitemap
    ===================================================================================================================
*/

@import "./core";
@import "./listings-page";
@import "./configuration-page";
