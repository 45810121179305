/*
    ===================================================================================================================
    Name: Grid
    Type: mixin
    Scope: layout
    Description:
    Mixin for creating CSS grids with flexbox fallback
    Note: It will be decided at some time that we need to support fallbacks for flexbox (floats)
    ===================================================================================================================
*/

/*
    ===================================================================================================================
    Config
    Local vars, placeholders, mixins and functions
    Note: Reference global vars, placeholders, mixins and functions before writing your own
    ===================================================================================================================
*/
@import 'variables';

$default-cols: 24;

@function col-width($column-width, $total-columns: $default-cols) {
    @return (100% / $total-columns) * $column-width;
}

/*  
    ===================================================================================================================
    Core
    ===================================================================================================================
*/

@mixin grid($default-cols: $default-cols) {
    display: block;
    display: flex;
    flex-wrap: wrap;
    margin-left: -($gutter-size / 2); // TODO shorthand
    margin-right: -($gutter-size / 2);
    overflow: hidden;
    @supports (display: grid) {
        display: grid;
        /* autoprefixer: off */
        grid-template-columns: repeat(auto-fit, (100% / $default-cols));
        grid-template-rows: auto;
    }
}

@mixin col($column-width, $total-columns: $default-cols) {
    width: col-width($column-width, $total-columns);
    flex: 0 0 col-width($column-width, $total-columns);
    padding: 0 ($gutter-size / 2);
    float: left;
    @supports (display: grid) {
        width: unset;
        grid-column: span $column-width;
    }
}
