/*  
    ===================================================================================================================
    Name: fx-btn-group
    Type: component
    Scope: JST pages
    Description: 
    Reusable button group component for single/multi-select
    
    Naming convention (BEM like)
    BLOCK: fx-block / fx-block-descriptor
    ELEMENTS: fx-block-element / fx-block-descriptor-element
    MODIFIERS: fx-block-element--modifier / fx-block-descriptor-element--modifier

    Examples
    fx-btn / fx-btn-primary / fx-btn-seconday / fx-btn-teriary
    fx-btn-icon / fx-btn-primary-icon / fx-btn-secondary-icon / fx-btn-teriary-icon
    fx-btn--is-active / fx-btn-primary--is-active / fx-btn-seconday--is-active / fx-btn-teriary--is-active
    ===================================================================================================================
*/

/*  
    ===================================================================================================================
    Imports 
    SASS @imports area
    ===================================================================================================================
*/

/*  
    ===================================================================================================================
    Config
    Local vars, placeholders, mixins and functions
    Note: Reference global vars, placeholders, mixins and functions before writing your own
    ===================================================================================================================
*/

$button-group-default-bgcolor: #90a4ae;
$button-group-active-bgcolor: #095fdc;
$button-group-focus-bgcolor: #095fdc;
$button-group-border-color: #90a4ae;
$button-group-color: $white;
$button-group-toggle-height: 40px;
$button-group-line-height: 40px;
$button-group-item-default-bg-color: $white;
$button-group-item-color: #7d8386;
$btn-group-border-radius: $font-size/4;

/*  
    ===================================================================================================================
    Base
    Common styles that apply grouped selectors i.e. .fx-block, .fx-block-descriptor {}
    Note: You may use [class*=""], [class^=""] etc.. but be careful
    ===================================================================================================================
*/

// TODO: Do we need all this?

$toggle-btn: '.mat-button-toggle';
.fx-btn-group {
    position: relative;
    height: $button-group-toggle-height;
    $this: &;

    &-checkbox,
    &-radio {
        appearance: none;
        -webkit-appearance: none; // TODO use autoprefixer

        &:hover + label,
        &:focus + label {
            background-color: $button-group-default-bgcolor;
            color: $button-group-color;
        }

        &:checked + label {
            background: $button-group-active-bgcolor;
            color: $button-group-color;

            &:hover,
            &:focus {
                background: darken($button-group-active-bgcolor, 20%);
                color: $button-group-color;
            }
        }
    }

    // TODO: Change this to set --engaged modifier to engaged table items - not using mat-checked
    &--engaged.mat-button-toggle-checked {
        .mat-button-toggle-label-content {
            background-color: $brand-success;
        }
    }

    &-label {
        position: relative;
        display: inline-block;
        font-size: 14px;
        padding: 0 12px;
        line-height: $button-group-line-height;
        background-color: $button-group-item-default-bg-color;
        color: $button-group-item-color;
        text-align: center;
        cursor: pointer;
        &--is-selected {
            background-color: $button-group-active-bgcolor;
            color: $button-group-color;
        }

        &:focus,
        &:hover {
            background-color: $button-group-default-bgcolor;
            color: $button-group-color;
        }
    }

    &--outline {
        #{$this}-label {
            background-color: transparent;
            border-top: 1px solid $gray-400;
            border-bottom: 1px solid $gray-400;
            border-left: 1px solid $gray-400;

            &:last-child {
                border-right: 1px solid $gray-400;
            }
        }
    }

    &--sm {
        height: $button-group-line-height * 0.6;
        #{$this}-label {
            line-height: $button-group-line-height * 0.6;
        }
    }
}
