/* 
    ===================================================================================================================
    Name: Components Manifest
    Type: Manifest
    Scope: components
    Description:
    Components manifest, no order is necessary here, however for neatness separate out by category
    ===================================================================================================================
*/

@import "./table/data-table";
@import "./table/data-table-configuration";

@import "./month-picker/month-picker";
@import "./single-multi-picker";

@import "./button-group";
@import "./chip";
@import "./drag-items";
@import "./icon";
@import "./progress-indicator";
// TODO: Remove title component
@import "./title";
@import "./card";

@import "./headers/page-header";

// * Whole component for one value item in a table? Switch for a modifier
@import "./contextual-value";

@import "./pagination";
