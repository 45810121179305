/* 
    ===================================================================================================================
    Name: form
    Type: material custom component
    Scope: all
    Description: Remove border from material forms
    ===================================================================================================================
*/

/* 
    ===================================================================================================================
    Core
    The actual rule declaration with nested elements and modifiers
    ===================================================================================================================
*/

.mat-form-field-appearance-legacy .mat-form-field-underline {
    height: 0px;
    display: none;
}
