/* 
    ===================================================================================================================
    Name: title
    Type: component
    Scope: all
    Description: component for titles
     
    Examples
    fx-title
    fx-title--subheading    
    fx-title--left    
    fx-title--right    
    ===================================================================================================================
*/

/* 
    ===================================================================================================================
    Imports
    SASS @imports area
    ===================================================================================================================
*/

// @import 'some-resource';

/* 
    ===================================================================================================================
    Config
    Local vars, placeholders, mixins and functions
    Note: Reference global vars by assigning to local variable to prevent overriding their values, use global
    placeholders, mixins and functions before writing your own
    ===================================================================================================================
*/

// $some-local-var: $some-global-var

/* 
    ===================================================================================================================
    Core
    The actual rule declaration with nested elements and modifiers
    ===================================================================================================================
*/

.fx-title {
    display: flex;
    padding: 0.5rem;
    cursor: default;
    &--subheading {
        font-size: $font-size * 0.75;
        padding: 0 0.5rem;
    }
    &--left {
        flex: 0 0 50%;
    }
    &--right {
        flex: 0 0 50%;
        text-align: right;
    }
}
