/* 
    ===================================================================================================================
    Name: draggable items
    Type: component
    Scope: all
    Description: component to move items
     
    Examples
    fx-drag-items    
    ===================================================================================================================
*/

/* 
    ===================================================================================================================
    Imports
    SASS @imports area
    ===================================================================================================================
*/

// @import 'some-resource';

/* 
    ===================================================================================================================
    Config
    Local vars, placeholders, mixins and functions
    Note: Reference global vars by assigning to local variable to prevent overriding their values, use global
    placeholders, mixins and functions before writing your own
    ===================================================================================================================
*/

// Active drop area color
$drag-item-active: #095fdb;

/* 
    ===================================================================================================================
    Core
    The actual rule declaration with nested elements and modifiers
    ===================================================================================================================
*/

.fx-drag-items {
    &-header {
        cursor: move;
    }
    &-hint {
        border: 2px dashed $drag-item-active;
    }
    &-drag {
        border: 1px solid #555;
    }
    &-transit {
        border: 1px solid $drag-item-active;
    }
    &-handle:hover {
        border: 1px solid $drag-item-active;
    }
    &-handle:hover * {
        cursor: move;
    }
    &-drag-over {
        background-color: $drag-item-active;
        border: 2px dashed $drag-item-active;
    }
    &-item {
        display: flex;
        justify-content: space-between;
        padding: $gutter-size / 2 $gutter-size;
        color: $gray-800;
        cursor: default;
        &:hover {
            background: transparent;
        }
        &--move {
            background: $brand-primary;
            color: $gray-200;
            cursor: move;
            &:hover {
                background: darken($brand-primary, 5%);
            }
        }
        &-title {
            flex: 0 1 66%;
            line-height: 1.3rem;
            font-weight: 600;
        }
        fx-chip {
            flex: 0 0 10%;
        }
        &-amount {
            flex: 0 0 34%;
            text-align: right;
            align-self: center;
            font-weight: 600;
        }
        &-badge {
            display: flex;
            flex: 0 0 34%;
        }
        &-remove {
            flex: 0 0 30px;
            text-align: center;
            line-height: 20px;
            margin: 10px -10px 0 10px !important;
            mat-icon {
                cursor: pointer;
            }
        }
        &-errors {
            margin-left: 12px;
            list-style: none;
            font-size: px-to-rem(13px);
            color: $brand-danger;
        }
    }
}
