/*
    ===================================================================================================================
    Name: fx-data-table-configration
    Type: component
    Scope: JST pages
    Description:
    Styling for fx-data-table config
    ===================================================================================================================
*/

/*
    ===================================================================================================================
    Config
    Local vars, placeholders, mixins and functions
    Note: Reference global vars, placeholders, mixins and functions before writing your own
    ===================================================================================================================
*/

$sticky-table-left-width: 250px;
$sticky-table-right-width: 500px;

/*
    ===================================================================================================================
    Base
    Common styles that apply grouped selectors i.e. .fx-block, .fx-block-descriptor {}
    Note: You may use [class*=""], [class^=""] etc.. but be careful
    ===================================================================================================================
*/

.fx-data-table {
    $this: &;

    &--config {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: stretch;
        flex-direction: row;
        margin-bottom: 150px;
        padding: 0;

        #{$this}-head,
        #{$this}-total {
            justify-content: flex-start;
        }

        #{$this}-head {
            height: px-to-rem(80px);
        }

        #{$this}-total {
            height: px-to-rem(60px);
        }

        #{$this}-body {
            position: relative;
            justify-content: flex-start;
            align-items: flex-start;
            overflow: visible;
            min-height: 65px;
            max-height: 65px;
            border-bottom: 1px solid $gray-300;
        }
        #{$this}-value {
            position: relative;
            display: flex;
            align-items: center;
            height: 100%;
            width: 100%;
            padding: $gutter-size/2;
            font-weight: $font-weight-bold;
            min-width: 68px;
            .reset {
                position: absolute;
                top: 0;
                left: 0;
                width: 16px;
                height: 16px;
                text-align: center;
                z-index: 2;
                border-radius: 8px;
                background: $brand-primary;
                mat-icon {
                    display: block;
                    width: 16px;
                    height: 16px;
                    font-size: 14px;
                    line-height: 16px;
                    border-radius: 8px;
                    color: $white;
                }
            }

            &--crm-engagement {
                display: block;
                position: absolute;
                right: 2px;
                bottom: 2px;
                line-height: 14px;
                font-size: 11px;
                padding: 0 6px;
                border-radius: 4px;
                z-index: $z-index-10;
                background-color: $brand-primary;
                color: $white;
            }
            &--is-assigned {
                display: block;
                position: absolute;
                right: 2px;
                top: 2px;
                line-height: 14px;
                font-size: 11px;
                padding: 0 6px;
                border-radius: 4px;
                z-index: $z-index-10;
                background-color: $brand-success;
                color: $white;
            }
        }
        #{$this}-scroll-block {
            flex: 1 1 auto;
            min-height: 500px;
        }

        #{$this}-group {
            &--sticky-left,
            &--sticky-right {
                flex: none;
            }

            &--center {
                display: inline-flex;
                flex: auto;
                // HACK: Slightly hacky to ensure the fx-select shows above the table rows
                overflow: auto;
                min-height: 410px;
                #{$this}-value {
                    width: 80px;
                    // Note: Overwriting FXUI disabled input behavior
                    .fx-btn-group .mat-button-toggle-button {
                        opacity: 1;
                    }
                }
                #{$this}-value--level {
                    width: 180px;
                }
                #{$this}-value--startDate,
                #{$this}-value--endDate,
                #{$this}-value--overrideScheduleStartDate,
                #{$this}-value--targetedSigningDate,
                #{$this}-value--dueDate {
                    width: 180px;
                }
                #{$this}-value--year {
                    width: 120px;
                    fx-select {
                        width: 100%;
                    }
                }
                #{$this}-value--schedules {
                    width: 340px;
                }
                #{$this}-value--complexity {
                    width: 120px;
                }
                #{$this}-value--financialStatements {
                    width: 152px;
                }

                #{$this}-value--name {
                    width: 350px;
                }

                #{$this}-value--frequency {
                    width: 150px;
                }

                #{$this}-value--amount {
                    width: 110px;
                }

                #{$this}-value--delete {
                    display: flex;
                    align-self: flex-start;
                    align-items: center;
                    height: 56px; // TODO magic numbers
                    line-height: 56px; // TODO magic numbers
                }

                #{$this}-value--feeType {
                    width: 185px;
                    overflow: hidden;
                }

                #{$this}-value--successPercentage {
                    width: 110px;
                }

                #{$this}-value--successValue {
                    width: 110px;
                }

                #{$this}-value--advancedOptions {
                    width: 180px;
                }
            }

            &--sticky-left {
                position: sticky;
                left: 0;
                border-right: 1px solid $gray-500;
                width: 250px;
                // TODO rework this
                .fx-data-table-value {
                    font-size: 0.95rem;
                    line-height: 1.3rem;
                    align-self: center;
                    color: #2b3134;
                }
            }
            &--sticky-right {
                position: sticky;
                right: 0;
                box-shadow: -2px 0px 5px 0px rgba(0, 0, 0, 0.2);
                z-index: 2;
                background-color: $gray-200;
                #{$this}-value {
                    position: relative;
                    flex: 0 0 auto;
                    align-self: center;
                    justify-content: center;
                    .fx-form-input {
                        // TODO rework this
                        width: 46px;
                        height: 40px;
                    }
                    &.fx-data-table-value--value {
                        flex-basis: 120px;
                        .cell-textbox {
                            text-align: right;
                            padding-right: 10px;
                        }
                    }
                }
                #{$this}-value-total-amount {
                    width: 100%;
                }
                &--reduce-width {
                    width: 250px !important;
                }
            }
        }

        #{$this}-head {
            background-color: $gray-300;
        }
        #{$this}-total {
            display: flex;
            align-items: center;
            align-self: flex-end;
            min-height: px-to-rem(64px);
            padding: 0 $gutter-size/2;
            font-size: 20px;
            background-color: $gray-300;
            color: $gray-900;

            #{$this}-value {
                flex: none;
                justify-content: flex-end;
            }

            &-amount {
                align-items: flex-end;
                font-weight: bold;
                justify-content: flex-end;
            }
        }
    }
}
