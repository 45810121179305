/* 
    ===================================================================================================================
    Name: tooltip
    Type: material custom component
    Scope: all
    Description: Tooltip to give further information on hover
    ===================================================================================================================
*/

/* 
    ===================================================================================================================
    Config
    Local vars, placeholders, mixins and functions
    Note: Reference global vars by assigning to local variable to prevent overriding their values, use global
    placeholders, mixins and functions before writing your own
    ===================================================================================================================
*/

// $some-local-var: $some-global-var

/* 
    ===================================================================================================================
    Core
    The actual rule declaration with nested elements and modifiers
    ===================================================================================================================
*/

.mat-tooltip {
    background-color: #1b1f22 !important;
    font-size: 13px !important;
}
