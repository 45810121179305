/* 
    ===================================================================================================================
    Name: Core Manifest
    Type: Manifest
    Scope: all
    Description:
    Core manifest, all imports must be in order of dependencies / specificity level
    ===================================================================================================================
*/
@import "variables";

@import "themes/index"; // TODO: Remove material overrides
@import "~@findex/fx-ui/styles/scss/_index.scss";
@import "fx-ui/index"; // TODO: Move to themes/index - fx-ui overrides
@import "base/index";
@import "components/index";
@import "layouts/index";

@import "~driver.js/dist/driver.min.css";
