/* 
    ===================================================================================================================
    Name: checkbox
    Type: material custom component
    Scope: all
    Description: Checkbox for forms and tables
    ===================================================================================================================
*/

/* 
    ===================================================================================================================
    Config
    Local vars, placeholders, mixins and functions
    Note: Reference global vars by assigning to local variable to prevent overriding their values, use global
    placeholders, mixins and functions before writing your own
    ===================================================================================================================
*/

// $some-local-var: $some-global-var
$checkbox-dimension: 30px;
$checkbox-hover-bgcolor: #90a4ae;

/* 
    ===================================================================================================================
    Core
    The actual rule declaration with nested elements and modifiers
    ===================================================================================================================
*/

.mat-checkbox {
    height: $checkbox-dimension !important;
}
.mat-checkbox-inner-container {
    background: white;
    height: $checkbox-dimension !important;
    width: $checkbox-dimension !important;
}
.mat-checkbox .mat-checkbox-ripple {
    display: none !important;
}
.mat-checkbox-disabled {
    cursor: default;
    opacity: 0.5;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background,
.mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
    background-color: #bdc8ce !important;
}
