/*
    ===================================================================================================================
    Name: fx-month-picker
    Type: component
    Scope: JST pages
    Description:
    Reusable component for selection of single/multiple months in year

    Naming convention (BEM like)
    BLOCK: fx-block / fx-block-descriptor
    ELEMENTS: fx-block-element / fx-block-descriptor-element
    MODIFIERS: fx-block-element--modifier / fx-block-descriptor-element--modifier

    Examples
    fx-btn / fx-btn-primary / fx-btn-seconday / fx-btn-teriary
    fx-btn-icon / fx-btn-primary-icon / fx-btn-secondary-icon / fx-btn-teriary-icon
    fx-btn--is-active / fx-btn-primary--is-active / fx-btn-seconday--is-active / fx-btn-teriary--is-active
    ===================================================================================================================
*/

/*
    ===================================================================================================================
    Imports
    SASS @imports area
    ===================================================================================================================
*/

// @import 'some-resource';
// ...

/*
    ===================================================================================================================
    Config
    Local vars, placeholders, mixins and functions
    Note: Reference global vars, placeholders, mixins and functions before writing your own
    ===================================================================================================================
*/

$picker-default-bgcolor: #90a4ae;
$picker-active-bgcolor: #095fdc;
$picker-focus-bgcolor: darken(#095fdc, 10%);
$picker-color: $white;
$picker-toggle-height: 40px;
$picker-line-height: 40px;
$picker-item-width: 50px;
$picker-item-default-bg-color: $white;
$picker-item-color: #7d8386;

/*
    ===================================================================================================================
    Base
    Common styles that apply grouped selectors i.e. .fx-block, .fx-block-descriptor {}
    Note: You may use [class*=""], [class^=""] etc.. but be careful
    ===================================================================================================================
*/

.fx-month-picker {
    //   position: relative;
    //border: 1px solid #bbb;
    $this: &;
    &-toggle {
        position: relative;
        display: block;
        width: $picker-item-width;
        height: $picker-toggle-height;
        padding: 0 10px;
        border: 0;
        line-height: $picker-toggle-height;
        background-color: $picker-default-bgcolor;
        color: $picker-color;
        font-size: 14px;
        display: none;
    }
    &-items {
        z-index: 1;
        display: flex;
        box-shadow: 2px 2px 7px #ccc;
        background-color: $white;
    }

    &-item {
        border-right: 1px solid #ccc;

        &--is-selected {
            background-color: $picker-active-bgcolor;
            color: $picker-color;
        }
        &:last-child {
            border-right: none;
        }
    }

    &-checkbox,
    &-radio {
        appearance: none;
        &:checked + label {
            // bad idea - hard to override probably reconsider doing this via class
            background: $picker-active-bgcolor;
            color: #fff;

            &:hover,
            &:focus {
                background: $picker-focus-bgcolor;
                color: #fff;
            }
        }

        &:checked + #{$this}-label-engaged {
            background: $brand-success;
            color: #fff;
        }
    }

    &-label,
    &-close {
        position: relative;
        display: inline-block;
        padding: 0 9px;
        line-height: $picker-line-height;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        background-color: $picker-item-default-bg-color;
        color: $picker-item-color;
        cursor: pointer;

        &:focus,
        &:hover {
            background-color: $picker-focus-bgcolor;
            color: $picker-color;
        }

        &-disabled {
            cursor: default;
            opacity: 0.5;
            pointer-events: none;
        }
    }

    &-label {
        &--is-selected {
            background-color: $picker-active-bgcolor;
            color: $picker-color;

            &:focus,
            &:hover {
                background-color: $picker-focus-bgcolor;
                color: $picker-color;
            }
        }

        &-disabled {
            cursor: default;
            opacity: 0.5;
            pointer-events: none;
        }
        &-engaged {
            background-color: $brand-success;
        }
    }

    &-close {
        border: 0;
    }
}
