/*
    ===================================================================================================================
    Name: button
    Type: material custom component
    Scope: all
    Description: Material button component
    ===================================================================================================================
*/

/*
    ===================================================================================================================
    Config
    Local vars, placeholders, mixins and functions
    Note: Reference global vars by assigning to local variable to prevent overriding their values, use global
    placeholders, mixins and functions before writing your own
    ===================================================================================================================
*/

// $some-local-var: $some-global-var

/*
    ===================================================================================================================
    Core
    The actual rule declaration with nested elements and modifiers
    ===================================================================================================================
*/

// TODO: In UI refresh of services, clean up and remove this file

button.mat-button,
button.mat-raised-button {
    background: #095fdc;
    color: #fff;
    min-width: 88px;
    line-height: 30px;
    padding: 0.1rem 1.5rem;
    margin: 0.25rem;
    border-radius: 40px;
}

.mat-button-toggle-group {
    box-shadow: none !important;
    background-color: #fff !important;
    &-disabled {
        cursor: default;
        opacity: 0.5;
        pointer-events: none;
    }
}

.fx-btn-group .mat-button-toggle-label-content {
    line-height: 40px;
    color: #7d8386;
    font-size: 14px;
    font-weight: 400;

    &:hover,
    &:focus {
        background: #90a4ae;
        color: #fff;
    }
}

.fx-btn-group .mat-button-toggle-button {
    background: #fff;
}

.fx-btn-group .mat-button-toggle-checked .mat-button-toggle-label-content {
    background-color: #095fdc;
    color: #fff;

    &:hover,
    &:focus {
        background: darken(#095fdc, 10%);
    }
}

.fx-btn-group .mat-button-toggle-checked.mat-button-toggle-disabled .mat-button-toggle-label-content {
    color: #fff;
}

.fx-btn-group .mat-button-toggle-disabled .mat-button-toggle-label-content {
    opacity: 0.5;
    pointer-events: none;
}
.fx-btn-group .mat-button-toggle-disabled {
    background-color: #fff;
}
.fx-btn-group .mat-button-toggle-button {
    border-right: 1px solid transparent;
}

.fx-btn-group .mat-button-toggle--engaged.mat-button-toggle-checked .mat-button-toggle-label-content {
    background-color: #3ab32a;
    color: #fff;
}
