/*
    ===================================================================================================================
    Name: Px to rem
    Type: function
    Scope: all
    Description:
    Converts px measurements to rem
    @param $target {number} the number of pixels to convert
    ===================================================================================================================
*/

// TODO: Figure out why this isn't pulling from FXUI

@function px-to-rem($target) {
    $rem: ($target / $font-size * 1rem);
    @return $rem;
}
