/* 
    ===================================================================================================================
    Name: radio
    Type: material custom component
    Scope: all
    Description: Radio button for options in forms
    ===================================================================================================================
*/

/* 
    ===================================================================================================================
    Config
    Local vars, placeholders, mixins and functions
    Note: Reference global vars by assigning to local variable to prevent overriding their values, use global
    placeholders, mixins and functions before writing your own
    ===================================================================================================================
*/

// $some-local-var: $some-global-var

/* 
    ===================================================================================================================
    Core
    The actual rule declaration with nested elements and modifiers
    ===================================================================================================================
*/

.mat-radio-button ~ .mat-radio-button {
    margin-left: 16px;
}
