/* 
    ===================================================================================================================
    Name: icons
    Type: material custom component
    Scope: all
    Description: Material icons
    ===================================================================================================================
*/

/* 
    ===================================================================================================================
    Config
    Local vars, placeholders, mixins and functions
    Note: Reference global vars by assigning to local variable to prevent overriding their values, use global
    placeholders, mixins and functions before writing your own
    ===================================================================================================================
*/

/* 
    ===================================================================================================================
    Core
    The actual rule declaration with nested elements and modifiers
    ===================================================================================================================
*/

.mat-icon {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.5rem;
}
