/*
    ===================================================================================================================
    Name: Core layout
    Type: Layout
    Scope: layouts
    Description:
    Core global layout scss for header, footer, sidebar and main
    ===================================================================================================================
*/

/*
    ===================================================================================================================
    Config
    Local vars, placeholders, mixins and functions
    Note: Reference global vars, placeholders, mixins and functions before writing your own
    ===================================================================================================================
*/

$header-height: 80px;
$sidebar-width: 80px;

/*
    ===================================================================================================================
    Core
    The actual rule declaration with nested elements and modifiers
    ===================================================================================================================
*/

.fx-l {
    &-header {
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        align-items: center;
        width: 100%;
        height: $header-height;
        z-index: $z-index-10;
        background: $brand-primary;

        &-brand {
            padding: $gutter-size 0 $gutter-size * 0.5 $gutter-size * 2;
            img {
                width: 250px;
            }
        }

        &-links {
            display: flex;
            flex: 1 1 auto;
            justify-content: flex-end;
            align-items: stretch;
        }

        &-link {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-width: $header-height;
            padding: 1rem;
            text-align: center;
            &-item {
                cursor: pointer;
                color: white;
                &-icon {
                    display: block;
                }
                &-text {
                    display: block;
                    font-size: 14px;
                }
            }

            &-user {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                min-width: 150px;
                padding-right: 0.5rem;
                &-image {
                    flex: 0 0 30%;
                    img {
                        height: 2.5rem;
                        border-radius: 10rem;
                    }
                }
                &-text {
                    flex: 0 0 70%;
                    text-align: left;
                    margin-left: 0.5rem;
                    color: white;
                    &-name {
                        max-width: 200px;
                    }
                }
            }
        }
    }

    &-side {
        position: fixed;
        left: 0;
        top: $header-height;
        width: $sidebar-width;
        height: calc(100vh - #{$header-height});
        z-index: $z-index-10;
        background-color: $brand-secondary;

        &-item {
            display: block;
            padding: $gutter-size * 1.25 0;
            font-size: $font-size * 0.7;
            text-transform: uppercase;
            text-align: center;
            height: 80px;
            color: white;
            &-image {
                width: 20px;
            }

            &:hover {
                background: darken($brand-primary, 5%);
            }

            &--active {
                background: $brand-primary;
                color: $white;
            }

            &-title {
                display: block;
                line-height: 0.7rem;
                margin-top: 4px;
            }
        }
    }

    &-main {
        position: relative;
        top: $header-height;
        left: $sidebar-width;
        width: calc(100% - #{$sidebar-width});
        min-height: calc(100vh - #{$header-height});
        background: $body-bg;
        &-banner {
            padding: $gutter-size * 0.5 $gutter-size;
            background-color: #ffb200;
            color: white;
            &-link {
                font-weight: bold;
                border-bottom: 1px solid white;
                color: white;
            }
        }
    }

    &-page {
        min-height: calc(100vh - #{$header-height});
        background-color: $white;
    }

    &-footer {
        background-color: white;
    }
}
