.fx-pagination {
    justify-content: center;
    padding: $gutter-size;

    li {
        &.current {
            border-radius: 4px;
            color: $white;
            background-color: $brand-primary;
        }
    }
}
