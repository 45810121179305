// Colors
$brand-primary: #d43f27;
$brand-secondary: #1b1f22;
$brand-tertiary: #d7dee2;
$body-color: #1b1f22;
$body-bg: #fff;
$section-bg: #f8f9fa;

// Spacing
$gutter-size: 1rem;

// Sizing
$font-size: 1rem;

// Borders and shadows
$dropdown-shadow: 0px 3px 5px 1px rgba(191, 191, 191, 0.7);

$color-border: #e1e6e9;

$white: rgba(255, 255, 255, 1) !default; //#fff
$gray-100: rgba(247, 247, 247, 1) !default; //e8eff2
$gray-200: rgba(233, 236, 239, 1) !default; //#e9ecef
$gray-300: rgba(222, 226, 230, 1) !default; //#dee2e6
$gray-400: rgba(206, 212, 218, 1) !default; //#ced4da
$gray-500: rgba(173, 181, 189, 1) !default; //#adb5bd
$gray-550: rgba(144, 164, 174, 1) !default; //#90a4ae
$gray-600: rgba(108, 117, 125, 1) !default; //#6c757d
$gray-700: rgba(73, 80, 87, 1) !default; //#495057
$gray-800: rgba(52, 58, 64, 1) !default; //#343a40
$gray-900: rgba(27, 31, 34, 1) !default; //#1B1F22

// define extra variables here as fx-ui style will only import once
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 600;

$z-index-behind: -1; // behind
$z-index-0: 0; // default
$z-index-10: 10; // headers
$z-index-above-all: 10000; // above all

$brand-info: rgba(36, 152, 219, 0.39);
$brand-warning: rgba(255, 234, 44, 0.712);
$brand-success: rgba(58, 179, 42, 0.769);
$brand-danger: rgba(212, 26, 26, 0.822);

$picker-focus-bgcolor: darken(#095fdc, 10%);

$support-color: #a61285;
