/* 
    ===================================================================================================================
    Name: card
    Type: material custom component
    Scope: all
    Description: Small component for accordion style cards
    ===================================================================================================================
*/

/* 
    ===================================================================================================================
    Config
    Local vars, placeholders, mixins and functions
    Note: Reference global vars by assigning to local variable to prevent overriding their values, use global
    placeholders, mixins and functions before writing your own
    ===================================================================================================================
*/

// $some-local-var: $some-global-var

/* 
    ===================================================================================================================
    Core
    The actual rule declaration with nested elements and modifiers
    ===================================================================================================================
*/

.fx-card {
    display: block;
    border: 1px solid $gray-300;
    &-header {
        font-weight: $font-weight-bold;
        padding: $gutter-size;
        background-color: $gray-200;
    }
    &-content {
        font-weight: $font-weight-bold;
        padding: $gutter-size / 2 0;
        background-color: $white;
    }
}
