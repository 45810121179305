$scrollbar-size: 12px;

::-webkit-scrollbar {
    height: $scrollbar-size;
    width: $scrollbar-size;
    position: fixed;
}

::-webkit-scrollbar-track {
    background: $gray-300;
}

::-webkit-scrollbar-thumb {
    background: $gray-500;

    &:hover {
        background: $gray-600;
    }
}

::-webkit-scrollbar-thumb:horizontal,
::-webkit-scrollbar-thumb:vertical {
    border-radius: $scrollbar-size / 2;
}
